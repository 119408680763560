<div class="service-title-area">
    <div class="page-title-content">
        <h2>Generic Cloud, AI and Alphabyte Insights</h2>
        <p>Are you ready to embrace the cloud revolution with generic cloud and AI?</p>

        <div class="col-lg-12 col-md-12" style="margin: 20px 0;">
            <button class="default-btn">GET STARTED<span></span></button>
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6" *ngFor="let service of services">
                <div class="features-box">
                    <div class="icon">
                        <img [src]="service.img" alt="">
                    </div>
                    <h3>{{ service.title }}</h3>
                    <p>{{ service.description }}</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <a class="read-more">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonials-area pt-100 pb-70" style="padding: 50px 0 70px !important;">
    <div class="container" style="max-width: 1150px;">
        <div class="testimonials_section">
            <div class="content_section">
                <h2>Why Alphabyte Insights</h2>
                <p>Cloud computing has fundamentally changed how customers perceive IT. The rapid evolution of these
                    transformative technologies is accelerating the pace at which new companies disrupt established
                    players across every industry. If you're ready to give your business a significant boost from the
                    cloud, AWS and Alphabyte Insights are here to help.</p>
                <p>We have extensive experience in building, deploying, and managing complex architectures on AWS,
                    demonstrated through hundreds of client engagements.</p>
                <p>We are an engineering-focused company with a highly technical team, where all our engineers are
                    certified or accredited by Amazon Web Services.</p>
            </div>
            <div class="image_section">
                <p>OUR AWS SPECIALIZATION</p>
                <div>
                    <img src="../../../../assets/img/aws_cloud_professional.png" alt="">
                    <img src="../../../../assets/img/aws_devops_professional.png" alt="">
                    <img src="../../../../assets/img/aws_developer_associate.png" alt="">
                    <img src="../../../../assets/img/aws_security_speciality.png" alt="">
                    <img src="../../../../assets/img/aws_solution_architect_associate.png" alt="">
                </div>

            </div>
        </div>
    </div>
</section>

<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="contact-cta-box mwidth-1000 wow animate__animated animate__fadeInUp" data-wow-delay="1s">
            <h3 style="font-size: 27px;">Learn more about AWS services with Alphabyte Insights.</h3>
            <a routerLink="/contact" class="default-btn">LEARN MORE</a>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>