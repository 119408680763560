<div class="{{navbarClass}}">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/logo1.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a href="#" class="nav-link" routerLink="/service" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Services
                            <!-- <i class='bx bx-chevron-down'></i> -->
                        </a>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link"
                            routerLink="/work" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Work</a></li>

                    <li class="nav-item"><a href="#" class="nav-link" routerLink="/insights" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Insights
                        </a>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link"
                            routerLink="/about" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">About us</a></li>

                    <!-- <li class="nav-item">
                        <a href="#" class="nav-link" routerLink="/features-1" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Press 
                        </a>
                    </li> -->
                </ul>
                <div class="others-options" id="btn">
                    <!-- <button mat-button (click)="openDialog()">Enquiry</button> -->
                    <a (click)="openDialog()" class="default-btn" >Enquiry<span></span></a>
                    <a class="optional-btn" routerLink="/contact" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Contact Us<span></span></a>
                </div>
            </div>
        </nav>
    </div>
</div>


<!-- <div class="{{navbarClass}}">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/logo.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>        

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a href="#" class="nav-link">Home <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="#" class="nav-link">With Animation Home <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home One (IT Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Two (IT Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-3" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Three (IT Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-4" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Four (SaaS Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-5" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Five (Chatbot)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-6" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Six (Chatbot)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-7" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Seven (Chatbot)</a></li>
                                </ul>
                            </li>

                            <li class="nav-item"><a href="#" class="nav-link">Without Animation Home <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/without-animation-home-1" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home One (IT Startup)</a></li>
                                    
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/without-animation-home-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Two (IT Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/without-animation-home-3" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Three (IT Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/without-animation-home-4" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Four (SaaS Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/without-animation-home-5" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Five (Chatbot)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/without-animation-home-6" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Six (Chatbot)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/without-animation-home-7" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Seven (Chatbot)</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                    <li class="nav-item"><a href="#" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="#" class="nav-link">Service <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services-1" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services One</a></li>
                                    
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services Two</a></li>
    
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Service Details</a></li>
                                </ul>
                            </li>

                            <li class="nav-item"><a href="#" class="nav-link">Features <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/features-1" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Features Style One</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/features-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Features Style Two</a></li>
                                </ul>
                            </li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/team" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Team</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/log-in" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Log In</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/error" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                        </ul>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/pricing" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                    <li class="nav-item">
                        <a href="#" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-1" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                        </ul>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                </ul>
                <div class="others-options">
                    <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i>Get Started<span></span></a>
                    <a routerLink="/log-in" class="optional-btn"><i class="bx bx-log-in"></i>Log In<span></span></a>
                </div>
            </div>
        </nav>
    </div>
</div> -->