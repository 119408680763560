<div class="work-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 style="text-align: center;">GAINES</h2>
            <p>Discovering Artificial Intelligence in
                Personal Vaults & Internal Systems
            </p>
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70 bg-f4f6fc" style="margin-bottom: 50px;">
    <div class="container">

        <div class="content-body">
            <h1>Table of Contents</h1>
            <div class="toc">
                <ul>
                    <li>01. Introduction</li>
                    <li>02. Problems & Solutions</li>
                    <li>03. Market Opportunity</li>
                    <li>04. Partners & Funding</li>
                </ul>
            </div>

            <!-- Introduction Section -->
            <div class="content-section">
                <h2 id="introduction">01. Introduction</h2>
                <p><strong>Who we are?</strong><br>
                    Alphabyte Insights LLC Technologies (OPC) Private Limited is an IT software development startup
                    committed to
                    delivering innovative software solutions and IT services across multiple advanced technology
                    domains.</p>
                <p>Alphabyte Insights LLC focuses primarily on Artificial Intelligence, Data Science, Scientific
                    Applications, and
                    Space Sciences, aiming to push the boundaries of what is possible in these fields.</p>
                <p>For more details, please visit <a
                        href="http://alphabyte-insights.com/">http://alphabyte-insights.com/</a></p>
            </div>

            <!-- Problems & Solutions Section -->
            <div class="content-section">
                <h2 id="problems-solutions">02. Problems & Solutions</h2>
                <h3>Problem Statements</h3>
                <ul>
                    <li>How can an individual user query across their storage systems by using integrated search tools
                        or querying software that allows access to easy retrieval of relevant information from diverse
                        storage sources?</li>
                    <li>How to create a knowledge resource for a team, centralize relevant documents, processes, and
                        insights in a shared digital platform, making them easily accessible for team collaboration and
                        future reference?</li>
                    <li>How to centralize a project’s knowledge base by consolidating all project-related information in
                        one accessible location, improving organization and collaboration across the project team?</li>
                    <li>How to share knowledge resources for easy access and distribution across users or teams?</li>
                </ul>
            </div>

            <!-- Unique Value Proposition Section -->
            <div class="content-section">
                <h2>Unique Value Proposition</h2>
                <ul class="value-prop-list">
                    <li>Self Integration Data Retrieval from Link</li>
                    <li>Multiformat Data Processing</li>
                    <li>Cross Domain Expertise</li>
                    <li>Sharable Knowledge Base</li>
                    <li>Deep Learning Based Text Understanding</li>
                </ul>
            </div>

            <!-- Market Opportunity Section -->
            <!-- <div class="market-section">
                <h3>Market Opportunity (By 2030)</h3>
                <div class="market-stats">
                    <div><strong>1.8 T</strong> - Artificial Intelligence</div>
                    <div><strong>1.5K B</strong> - Enterprise Knowledge Management</div>
                    <div><strong>132 B</strong> - Enterprise AI and Data Analytics</div>
                    <div><strong>19 B</strong> - Customer Support and Enterprise Chatbots</div>
                    <div><strong>3,147,300,000</strong> - Number of users analyzed in our market research – Microsoft
                        Analytics</div>
                </div>
            </div> -->

            <div class="market-container">
                <h1 class="market-title">Market Size (By 2030)</h1>
                <div class="market-content">

                    <!-- Market Data Section -->
                    <div class="market-data">
                        <div class="market-item">
                            <div class="market-value">1.8 T</div>
                            <div>Artificial Intelligence</div>
                        </div>
                        <div class="market-item">
                            <div class="market-value">1.5K B</div>
                            <div>Enterprise Knowledge Management</div>
                        </div>
                        <div class="market-item">
                            <div class="market-value">132 B</div>
                            <div>Enterprise AI and Data Analytics</div>
                        </div>
                        <div class="market-item">
                            <div class="market-value">19 B</div>
                            <div>Customer Support and Enterprise Chatbots</div>
                        </div>
                    </div>

                    <!-- Donut Chart Section -->
                    <!-- <div class="market-chart">
                        <div class="donut-chart"></div>
                    </div> -->
                </div>
            </div>

            <!-- Partners & Funding Section -->
            <div class="funding-section">
                <h3 style="font-size: 50px;">3,147,300,000</h3>
                <div class="funding-amount">
                    <div>Number of users analyzed in our
                        market research – Microsoft Analytics</div>
                </div>
            </div>

            <!-- Partners & Funding Section -->
            <div class="funding-section">
                <h3>Partners & Funding</h3>
                <h3 style="font-size: 50px;">$150,000</h3>
                <div class="funding-amount">
                    <div>In the first Quarter of the Financial Year 2024</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let element of works"
                style="margin-bottom: 20px; display: flex; flex-wrap: wrap;">
                <div class="features-box-one" style="text-align: start;">
                    <img [src]="element.img" alt="" style="height: 250px; padding: 10px;">
                    <div class="content_section">
                        <h3 style="font-size: 20px;">{{ element.title }}</h3>
                        <p>{{ element.description }}</p>
                        <a class="read-more" [routerLink]="'/work/' + element.id">Read More <i
                                class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>