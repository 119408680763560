<div class="main-banner main-banner-one">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>Create Your Ideal Team for Cloud Projects</h1>
                                <p>We are a premier provider of cloud-based developers, Java Full Stack developers, and
                                    Data Engineering experts. Innovative companies choose us to enhance quality,
                                    minimize risk, and maximize ROI from their software development initiatives. We
                                    collaborate with some of the most prominent and esteemed staffing and solutions
                                    firms globally.</p>
                                <a routerLink="/contact" class="default-btn">
                                    <!-- <i class="bx bxs-hot"></i> -->
                                    Hire Expert Cloud Developers<span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="banner-image mbanner-bg-one">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="animate-banner-image">
                                <img src="../../../../assets/img/modern-equipped-computer-lab_23-2149241214.jpg"
                                    alt="image">
                            </div>
                        </div>
                    </div>
                    <img src="../../../../assets/img/banner-slider/unsplash-image-YOXSC4zRcxw.jpg" class="mbanner-img"
                        alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="shape20"><img src="assets/img/shape/shape19.png" alt="image"></div>
    <div class="shape21"><img src="assets/img/shape/shape20.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape18.png" alt="image"></div>
    <div class="shape22"><img src="assets/img/shape/shape21.png" alt="image"></div>
    <div class="shape23"><img src="assets/img/shape/shape22.svg" alt="image"></div>
    <div class="shape24"><img src="assets/img/shape/shape23.png" alt="image"></div>
    <div class="shape26"><img src="assets/img/shape/shape25.png" alt="image"></div>
</div>

<div class="features-card-section pt-100 pb-70 bg-f8fbfa">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="single-features-card tx-center">
                    <img src="../../../../assets/img/qualified-talent.png" alt="">
                    <h3><a>Certified Cloud Experts</a></h3>
                    <p>Certified Cloud Talent for your software engineering teams, offering specialist skills in Generic
                        Cloud, AI and Alphabyte Insights,
                        Google Cloud, Microsoft Azure, and Salesforce.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <div class="single-features-card tx-center">
                    <img src="../../../../assets/img/agile-team.png" alt="">
                    <h3><a>Product Delivery Teams</a></h3>
                    <p>On-demand product delivery teams for managed execution of your projects. Utilizing proven
                        development methodologies, you can reduce risks and improve your ROI.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                <div class="single-features-card tx-center">
                    <img src="../../../../assets/img/oursource-product-dev.png" alt="">
                    <h3><a>Outsourced Development</a></h3>
                    <p>Outsource product development for your idea with our comprehensive solution, which includes
                        product management, design, development, and delivery expertise.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area bg-right-shape ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content it-service-content">
                <div class="content left-content">
                    <h2>Begin Your Journey</h2>
                    <p>With Flexible Cloud Talent</p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Java Development
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Data Engineering
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Data Mining Expertise
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Generic Cloud, AI and Alphabyte Insights Certified
                                Engineers
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>
                                Managed Product Execution Teams
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Offshore development at our tech center in Noida,
                                India
                            </div>
                        </div>
                    </div>
                    <a routerLink="/contact" class="default-btn">
                        <!-- <i class="bx bxs-spreadsheet"></i>  -->
                        Learn More<span></span></a>
                </div>
            </div>
            <div class="services-image wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                <div class="image">
                    <img src="../../../../assets/img/experts.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>


<section class="service_section">
    <h1>Our <span>Services</span></h1>
    <mat-tab-group [selectedIndex]="selectedIndex">
        <mat-tab *ngFor="let tab of tabs">
            <ng-template mat-tab-label>
                {{ tab.label }}
            </ng-template>
            <section class="services-area bg-left-color bg-left-shape bg-f4f6fc ptb-100">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                            <div class="image">
                                <img [src]="tab.img" alt="image">
                            </div>
                        </div>
                        <div class="services-content it-service-content">
                            <div class="content">
                                <h2>{{ tab.heading }}</h2>
                                <p>{{ tab.description }}</p>
                                <a [routerLink]="tab.link" class="default-btn">
                                    <!-- <i class="bx bxs-spreadsheet"></i> -->
                                    Learn More<span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </mat-tab>
    </mat-tab-group>
</section>



<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="contact-cta-box mwidth-1000 wow animate__animated animate__fadeInUp" data-wow-delay="1s">
            <h3>Bring your ideas to life with our cloud experts.</h3>
            <a routerLink="/contact" class="default-btn">
                Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
</section>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2>Alphabyte Insights Advantage</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let feature of features; let i = index">
                <div class="features-box-one wow animate__animated animate__fadeInLeft"
                    [attr.data-wow-delay]="feature.delay">
                    <img [src]="feature.img" alt="">
                    <h3>{{ feature.title }}</h3>
                    <p>{{ feature.description }}</p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Work</span></h2>
        </div>
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item" *ngFor="let feedback of works">
                <img [src]="feedback.img" alt="image" style="height: 280px !important; width: 280px !important;">
                <div class="feedback-desc">
                    <div class="client-info">
                        <h3>{{ feedback.title }}</h3>
                    </div>
                    <p>{{ feedback.description }}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- 
<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our Experts, Always Ready to Assist You</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6" *ngFor="let member of teamMembers; let i = index">
                <div class="single-team-box wow animate__animated animate__fadeInUp"
                    [attr.data-wow-delay]="'0.' + (i + 2) + 's'">
                    <div class="image">
                        <img [src]="member.img" alt="image" style="height: 280px; width: 280px;">
                        <ul class="social">
                            <li *ngIf="member.socialLinks.facebook"><a [href]="member.socialLinks.facebook"
                                    target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li *ngIf="member.socialLinks.twitter"><a [href]="member.socialLinks.twitter"
                                    target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li *ngIf="member.socialLinks.linkedin"><a [href]="member.socialLinks.linkedin"
                                    target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li *ngIf="member.socialLinks.instagram"><a [href]="member.socialLinks.instagram"
                                    target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>{{ member.name }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>We have supported some of the world's leading brands</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" *ngFor="let logo of clientLogos"
                [attr.data-wow-delay]="logo.delay">
                <a href="#">
                    <img [src]="logo.img" alt="client logo">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Our <span>People</span></h2>
                    <p>At Alphabyte Insights, we believe that training and education are
                        crucial for developing an exceptional workforce capable of tackling the world's most complex
                        challenges. We recruit top technical talent and provide them with opportunities to enhance their
                        skills in cloud technologies like AWS, GCP, and Azure, as well as in soft skills such as
                        effective communication, collaboration, ethics, innovation, creativity, and leadership.</p>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                    <img src="../../../../assets/img/group_people.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="subscribe-content border-radius-0">
    <h2>Our Platform Expertise</h2>
    <p>We collaborate with the world's leading technology providers to deliver solutions tailored to
        your unique needs. Our certified technology experts in AWS, GCP, Azure, and Salesforce will help you achieve
        optimal results with these technologies.</p>
    <form class="newsletter-form">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6">
                <img src="../../../../assets/img/aws_logo.png" alt="">
            </div>
            <div class="col-lg-3 col-md-6">
                <img src="../../../../assets/img/gcp_logo.png" alt="">
            </div>
            <div class="col-lg-3 col-md-6">
                <img src="../../../../assets/img/microsoft_logo.png" alt="">
            </div>
            <div class="col-lg-3 col-md-6">
                <img src="../../../../assets/img/salesforce_logo.png" alt="">
            </div>
        </div>
    </form>
    <div class="shape14"><img src="assets/img/shape/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape/shape14.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape17.png" alt="image"></div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="col-lg-12 col-md-12">
            <div class="blog-notes wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                <p>Insights to help you do what you do better, faster and more profitably. <a>Read Full Blog</a></p>
            </div>
        </div>
    </div>
</section>