import { Component } from '@angular/core';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent {
  tabs = [
    {
      label: 'CERTIFIED CLOUD TALENT',
      img: '../../../../assets/img/girl_with_tablet.jpg',
      heading: 'CERTIFIED CLOUD TALENT',
      description: `When executing your technology projects with confidence, it's crucial to hire top talent. At Alphabyte Insights, our engineers undergo a rigorous qualification process and pass a certification exam. According to a recent survey, 93% of IT decision-makers believe certifications enhance team value. Alphabyte Insights Engineers are certified in AWS, GCP, or Azure, ensuring they can immediately contribute to your complex cloud projects with high productivity from day one.`,
      link: '/contact'
    },
    {
      label: 'PRODUCT DELIVERY TEAMS',
      img: '../../../../assets/img/businesspeople-are-looking-laptop-working-startup-project-office_232070-14958.jpg',
      heading: 'PRODUCT DELIVERY TEAMS',
      description: `Creating a modern software product today requires cross-domain expertise and a collaborative approach. To deliver a compelling experience, you need the skills of designers, backend developers, front-end developers, AI/ML experts, QA professionals, and DevOps specialists, among others. Hiring such a diverse team can take months, and new hires often need time to become productive.
      Veridic Solutions offers Managed Agile Teams that are productive from day one. We manage the team's productivity and output, ensuring they adhere to best-in-class agile development methodologies. You gain full visibility into the team's progress through daily standups, reports, and metrics. By automating your product development, you can concentrate on strategic issues to grow your business.`,
      link: '/contact'
    },
    {
      label: 'OUTSOURCED PRODUCT DEVELOPMENT',
      img: '../../../../assets/img/business-person-looking-finance-graphs_23-2150461333.jpg',
      heading: 'OUTSOURCED PRODUCT DEVELOPMENT',
      description: `If you have an idea and want to bring it to life, our independent product development service is perfect for you. We start by discussing your concept and refining it to meet Desirability, Viability, and Feasibility goals. We create a roadmap for your concept evolution, starting with a Minimum Viable Product (MVP) and building it to be ready for scaling. Our multi-disciplinary team then implements your product using existing components and building blocks, significantly reducing development time. This approach ensures you can launch a market-ready product without breaking the bank.`,
      link: '/contact'
    }
  ];

  features = [
    {
      img: '../../../../assets/img/qualified-talent.png',
      title: 'Qualified Talent',
      description: `All our candidates are thoroughly vetted for their exceptional technical skills and most undergo a rigorous certification process in their technology domains. Additionally, we screen and continually train our talent in soft skills such as effective communication, collaboration, ethics, and innovation, ensuring they make a positive impact on any team they join.`,
      delay: '.2s'
    },
    {
      img: '../../../../assets/img/robust-process.png',
      title: 'Robust Processes',
      description: `Our engineers are adept with a range of project management and collaboration tools and can seamlessly adapt to the tools your team uses. Whether you prefer GitHub or GitLab, Slack or Teams, Gmail or Outlook, or any other tool, our talent will integrate smoothly and become an extension of your team.`,
      delay: '.4s'
    },
    {
      img: '../../../../assets/img/security-ip.png',
      title: 'Security & IP Protection',
      description: `Whether you’re developing a new product or enhancing an existing one, the ideas, expertise, and intellectual property always remain yours, even if we contribute to the process. Our services are strictly work-for-hire, and we are committed to confidentiality and IP protection. We have robust processes in place to ensure your intellectual property is secure when you work with us.`,
      delay: '.6s'
    },
    {
      img: '../../../../assets/img/training.png',
      title: 'Upskilling',
      description: `We offer rigorous training to our employees in areas such as DevOps, Full-Stack Development, Java, Hadoop, Data Science, Workflow Automation, Salesforce, and other leading technologies. Our developers stay up-to-date with the latest technical skills, ensuring you always receive the expertise needed to drive your projects to success.`,
      delay: '.2s'
    },
    {
      img: '../../../../assets/img/flexibility.png',
      title: 'Flexibility',
      description: `We recognize that every customer is unique, and we are flexible enough to tailor our services to your specific needs. Whether you require talent to augment your teams on a short-term or long-term basis, or if you need to establish a fully managed agile development team, we are here to collaborate and deliver.`,
      delay: '.4s'
    },
    {
      img: '../../../../assets/img/roi.png',
      title: 'ROI',
      description: `Hiring a skilled resource from us boosts your ROI since you only payfor their productive time. We cover recruitment fees, employee and health benefits, employertaxes, insurance, office space, and other overhead costs. With no ramp-up time required, ourtalent will be productive from day one.`,
      delay: '.6s'
    }
  ];

  works = [
    {
      img: '../../../../assets/img/images/cover-letter-to-hiring-manager.webp',
      title: 'Consulting',
      description: "Consulting in IT involves providing expert advice and solutions to help organizations optimize their technology infrastructure, improve efficiency, and drive innovation. IT consultants analyze current systems, identify areas for improvement, and recommend tailored strategies to meet business goals.",
    },
    {
      img: '../../../../assets/img/images/premium_photo-1661277816311-28cced31f998.jpg',
      title: 'Implementing',
      description: "Implementing IT solutions involves turning strategies into actionable steps to enhance a company’s technology infrastructure. This process requires careful planning, effective communication, and collaboration among teams. Key tasks include configuring systems, integrating new technologies, and ensuring data security.",
    },
    {
      img: '../../../../assets/img/images/shaking-hand-speaker_1098-14124.jpg',
      title: 'Hiring Recruitment',
      description: "We're looking for talented IT professionals to join our innovative team. If you have a passion for technology, problem-solving skills, and a drive to stay ahead in a fast-paced industry, we want to hear from you. Our ideal candidates are proficient in modern programming languages, cloud platforms, and agile methodologies.",
    },
    {
      img: '../../../../assets/img/images/project_development.png',
      title: 'Project Development',
      description: "Effective project development in IT involves a structured approach to planning, executing, and managing technology projects. This process includes defining clear objectives, gathering requirements, and designing solutions tailored to meet specific needs. Implementing agile methodologies can enhance flexibility and responsiveness to changes.",
    },
    // {
    //   img: '../../../../assets/img/image_2024_07_25T11_57_38_946Z.png',
    //   title: 'Integration of Business and Information Technology – A Unified Framework',
    //   description: "Fusion of Business & Information Technology (FOBIT) is a comprehensive framework that includes an integrated dashboard, business service management, scenario assessments and simulations, actionable roadmaps with risk management, and robust IT governance and compliance. FOBIT enables senior management to make informed, aligned, and optimized decisions for effective and efficient IT operations.",
    // },
  ];

  // teamMembers = [
  //   {
  //     img: '../../../../assets/img/dummy_image.png',
  //     name: 'Person1',
  //     // position: 'CHIEF EXECUTIVE OFFICER',
  //     socialLinks: {
  //       facebook: '#',
  //       twitter: '#',
  //       linkedin: '#',
  //       instagram: '#'
  //     }
  //   },
  //   {
  //     img: '../../../../assets/img/dummy_image.png',
  //     name: 'Person2',
  //     // position: 'CHIEF OPERATING OFFICER',
  //     socialLinks: {
  //       facebook: '#',
  //       twitter: '#',
  //       linkedin: '#',
  //       instagram: '#'
  //     }
  //   },
  //   {
  //     img: '../../../../assets/img/dummy_image.png',
  //     name: 'Person3',
  //     // position: 'BOARD MEMBER',
  //     socialLinks: {
  //       facebook: '#',
  //       twitter: '#',
  //       linkedin: '#',
  //       instagram: '#'
  //     }
  //   },
  //   {
  //     img: '../../../../assets/img/dummy_image.png',
  //     name: 'Person4',
  //     // position: 'BOARD MEMBER',
  //     socialLinks: {
  //       facebook: '#',
  //       twitter: '#',
  //       linkedin: '#',
  //       instagram: '#'
  //     }
  //   }
  // ];

  clientLogos = [
    { img: '../../../../assets/img/3M.png', delay: '0.2s' },
    { img: '../../../../assets/img/bestbuy.png', delay: '0.4s' },
    { img: '../../../../assets/img/chevron.png', delay: '0.8s' },
    { img: '../../../../assets/img/capital-one.png', delay: '1s' },
    { img: '../../../../assets/img/collabera.png', delay: '1.2s' },
    { img: '../../../../assets/img/comcast.png', delay: '1.4s' },
    { img: '../../../../assets/img/cvs.png', delay: '1.6s' },
    { img: '../../../../assets/img/disney.png', delay: '1.8s' },
    { img: '../../../../assets/img/hpe.png', delay: '2.4s' },
    { img: '../../../../assets/img/kforece.png', delay: '2.6s' },
    { img: '../../../../assets/img/manpower.png', delay: '2s' },
    { img: '../../../../assets/img/randstad.png', delay: '2.2s' },
    { img: '../../../../assets/img/roberthalf.png', delay: '2.8s' },
    { img: '../../../../assets/img/samsung.png', delay: '3s' },
    { img: '../../../../assets/img/schlumberger.png', delay: '3.2s' },
    { img: '../../../../assets/img/shell.png', delay: '3.4s' },
    { img: '../../../../assets/img/staples.png', delay: '3.6s' },
    { img: '../../../../assets/img/starbucks.png', delay: '3.8s' },
    { img: '../../../../assets/img/teksystems.png', delay: '3.8s' },
    { img: '../../../../assets/img/united.png', delay: '3.8s' }
  ];

  selectedIndex = 0;

  constructor() { }

}
