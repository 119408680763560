<div class="service-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Insights</h2>
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70 bg-f4f6fc" style="margin-bottom: 50px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let element of elements"
                style="margin-bottom: 20px; display: flex; flex-wrap: wrap;">
                <div class="features-box-one" style="text-align: start;">
                    <div class="content_section">
                        <h3 style="font-size: 20px;">{{ element.title }}</h3>
                        <p>{{ element.content }}</p>
                        <a class="read-more">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                    <div [style.background-color]="element.color" style="height: 10px;"></div>
                </div>
            </div>
        </div>
    </div>
</section>