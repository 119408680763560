<footer class="footer-area">
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo1.png" alt="image"></a>
                    </div>
                    <p>Alphabyte Insights is a leading developer of cloud-based software. We combine talented minds with
                        learning and innovation to solve the most complex issues. Our cloud certified engineers are
                        uniquely positioned to serve your cloud projects</p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Services</h3>
                    <ul class="services-list">
                        <li><a routerLink="/">On-Demand Talent</a></li>
                        <li><a routerLink="/">Managed Teams</a></li>
                        <li><a routerLink="/">Product Development</a></li>
                        <li><a routerLink="/">Why Alphabyte Insights</a></li>
                        <!-- <li><a routerLink="/">Latest News</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Expertise</h3>
                    <ul class="services-list">
                        <li><a routerLink="/work">Work</a></li>
                        <li><a routerLink="/service">AWS/Cloud</a></li>
                        <!-- <li><a routerLink="/">Product Development</a></li>
                        <li><a routerLink="/">Why Alphabyte Insights</a></li> -->
                        <!-- <li><a routerLink="/">Latest News</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Who We Are</h3>
                    <ul class="support-list">
                        <li><a routerLink="/">Culture</a></li>
                        <li><a routerLink="/">Innovation</a></li>
                        <li><a routerLink="/">Leadership</a></li>
                        <li><a routerLink="/">Enquiry</a></li>
                        <!-- <li><a routerLink="/">Contact Us</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Careers</h3>
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>Location: <a href="https://goo.gl/maps/MQ78iGP5g9VgZcJ38" target="_blank">925 Summer Rain
                                Way, Acworth, GA 30102</a></li>
                        <li>Email: <a>sai&#64;alphabyte-insights.com</a></li>
                        <li>Phone: <a href="tel:+16787014893">+1 678 701 4893</a></li>
                    </ul>
                    <ul class="social">
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li> -->
                        <li><a href="https://www.linkedin.com/company/alphabyte-insights-llc/about/?viewAsMember=true"
                                target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>Copyright 2024, Alphabyte Insights</p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>