import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavbarComponent } from '../../layouts/navbar/navbar.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrl: './career.component.scss'
})
export class CareerComponent {


  contactForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CareerComponent>,
  ) {
    dialogRef.disableClose = true;
    this.contactForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      businessEmail: ['', [Validators.required, Validators.email]],
      industry: [''],
      companyName: [''],
      jobFunction: [''],
      message: [''],
      agreeToMessaging: [false, Validators.requiredTrue]
    });
  }

  onSubmit() {
    if (this.contactForm.valid) {
      console.log(this.contactForm.value);
    }
  }

  closeForm() {
    console.log('Form closed');
  }


}