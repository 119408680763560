import { Component } from '@angular/core';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrl: './work.component.scss'
})
export class WorkComponent {
  works = [
    {
      id: '1',
      img: '../../../../assets/img/images/consulting.jpg',
      title: 'Consulting',
      description: 'Consulting in IT involves providing expert advice and solutions.',
    },
    {
      id: '2',
      img: '../../../../assets/img/images/implementing.webp',
      title: 'Implementing',
      description: 'Implementing IT solutions involves turning strategies into actionable steps.',
    },
    {
      id: '3',
      img: '../../../../assets/img/images/hiring.webp',
      title: 'Hiring Recruitment',
      description: "We're looking for talented IT professionals to join our innovative team.",
    },
    {
      id: '4',
      img: '../../../../assets/img/images/project_development.png',
      title: 'Project Development',
      description: 'Effective project development in IT involves a structured approach to planning, executing, and managing technology projects.',
    },
    // {
    //   id: '5',
    //   img: '../../../../assets/img/image_2024_07_25T11_57_38_946Z.png',
    //   title: 'Integration of Business and Information Technology – A Unified Framework',
    //   description: 'Fusion of Business & Information Technology (FOBIT) is a comprehensive framework.',
    // },
  ];
  
  constructor() { }


}