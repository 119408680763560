import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {MatTabsModule} from '@angular/material/tabs';
import { ServiceComponent } from './components/pages/service/service.component';
import { InsightComponent } from './components/pages/insight/insight.component';
import { CareerComponent } from './components/pages/career/career.component';
import { WorkComponent } from './components/pages/work/work.component';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { WorkDetailsComponent } from './components/pages/work-details/work-details.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PreloaderComponent,
    FooterComponent,
    HomeOneComponent,
    AboutComponent,
    WorkComponent,
    ContactComponent,
    ServiceComponent,
    InsightComponent,
    CareerComponent,
    WorkDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTabsModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
  ],
  providers: [
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
