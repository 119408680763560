<div class="about-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>About Us</h2>
      <p>We bring together talented minds with a focus on learning and innovation to tackle the most complex challenges.
      </p>
    </div>
  </div>
</div>

<section class="about-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="about-content" >
          <h2>About Us</h2>
          <p>  
            At Alphabyte Insights, we are dedicated to transforming businesses through innovative IT solutions. With a team of skilled professionals and years of industry experience, we specialize in delivering customized software development, cloud solutions, cybersecurity, and IT consulting services. Our mission is to empower our clients by providing technology-driven solutions that are efficient, scalable, and tailored to meet their unique needs. We pride ourselves on our commitment to excellence, integrity, and customer satisfaction, making us a trusted partner in your digital journey.</p>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="about-image">
          <img src="../../../../assets/img/images/pexels-photo-3184611.jpeg" alt="image">
        </div>
      </div>
    </div>
  </div>
</section>
<!-- 
<section class="app-download-area ptb-100" style="padding: 50px !important;">
  <div class="container">
    <div style="max-width: 75%; margin: 10px auto;">
      <div style="text-align: center; margin-bottom: 70px;">
        <h2 style="font-size: 22px;">“Our core values are the foundation of our work. As we collaborate to achieve
          success, these values guide us in delivering exceptional outcomes for your business.”</h2>
        <p>— BRYAN HOWLIN (CEO, ALPHABYTE INSIGHTS)</p>
      </div>
      <div>
        <ng-container *ngFor="let value of coreValues">
          <h3 style="font-weight: 400; font-size: 24px;">{{ value.title }}</h3>
          <p>{{ value.description }}</p>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<section class="team-area pt-100 pb-70">
  <div class="container">
    <div class="section-title" style="max-width: 100%;">
      <h2 style="margin-bottom: 20px; font-size: 50px; font-weight: 600;">Leadership</h2>
      <h5 style="margin-bottom: 15px; font-size: 22px; font-weight: 500;">Motivated by the possibilities to make an
        impact</h5>
      <p>We have spent decades building up some of the world's most important tech companies and we bring this
        experience with us on our journey together. We are committed to ensuring our employees and the communities where
        they do business live fulfilling lives, while simultaneously growing constantly</p>
    </div>
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-6" *ngFor="let member of teamMembers">
        <div class="single-team-box wow animate__animated animate__fadeInUp" [attr.data-wow-delay]="member.delay">
          <div class="image">
            <img [src]="member.img" alt="image" style="height: 330px; width:330px;">
            <ul class="social">
              <li *ngFor="let social of member.socialLinks">
                <a [href]="social.url" target="_blank">
                  <i [class]="'bx bxl-' + social.platform"></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="content">
            <h3>{{ member.name }}</h3>
            <span>{{ member.position }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="our-loving-clients ptb-100 bg-f4f5fe">
  <div class="container">
    <div class="section-title" style="max-width: 950px;">
      <h2>Companies whom we have helped create their future</h2>
      <p>Leading companies and startups are are making a change in this world and they've used us to make it
        happen. Our people work hard every day to make technology happen for the change overcoming challenges
        and solving hard problems for our customers.</p>
    </div>
    <div class="clients-logo-list align-items-center">
      <div class="single-clients-logo wow animate__animated animate__fadeInUp" *ngFor="let client of clients"
        [attr.data-wow-delay]="client.delay" style="max-width: 16%;">
        <a href="#">
          <img [src]="client.imgSrc" alt="image">
        </a>
      </div>
    </div>
  </div>
</section>

<section class="free-trial-area ptb-100 ">
  <div class="container">
    <div class="free-trial-content">
      <h2 style="color: #fff;">Join us and make a difference.</h2>
      <p style="color: #fff;">If you seek opportunity and excitement, Alphabyte Insights is the place for you. Whether
        you're passionate about transforming businesses with cloud technologies or making an impact across various
        industries, we’re always on the lookout for creative talent to join our rapidly growing team.</p>
      <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> BROWSE CURRENT
        OPENINGS<span></span></a>
    </div>
  </div>
  <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
  <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
  <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
  <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section> -->