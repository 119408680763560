<div class="enquiry-form">
  <div style="display: flex;flex-direction: row; justify-content: space-between;align-items: center;">
    <div style="width: 50%;">
      <img src="../../../../assets/img/logo1.png" alt="">
    </div>
    <div>
      <button mat-icon-button (click)="dialogRef.close()">
        &#10006;
      </button>
    </div>
  </div>

  <h1>Get in touch</h1>
  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" style="padding: 0 20px;">
    <div class="form_section">
      <div class="form-group">
        <label for="firstName">First Name</label>
        <input id="firstName" formControlName="firstName" type="text" required>
      </div>
      <div class="form-group">
        <label for="lastName">Last Name</label>
        <input id="lastName" formControlName="lastName" type="text" required>
      </div>
      <div class="form-group">
        <label for="businessEmail">Business Email</label>
        <input id="businessEmail" formControlName="businessEmail" type="email" required>
      </div>
      <div class="form-group">
        <label for="industry">Industry</label>
        <select id="industry" formControlName="industry">
          <option value="">Select</option>
        </select>
      </div>
      <div class="form-group">
        <label for="companyName">Company Name</label>
        <input id="companyName" formControlName="companyName" type="text">
      </div>
      <div class="form-group">
        <label for="jobFunction">Job Function</label>
        <select id="jobFunction" formControlName="jobFunction">
          <option value="">Select</option>
        </select>
      </div>
    </div>
    <div class="message_group">
      <label for="message">Message</label>
      <textarea id="message" formControlName="message"></textarea>
    </div>
    <div class="message_group">
      <label style="margin: 20px 0;">
        <input type="checkbox" formControlName="agreeToMessaging"> I agree to receive messaging from Alphabyte Insights.
      </label>
    </div>
    <button class="btn" type="submit">Get Started</button>
  </form>
</div>