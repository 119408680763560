import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { InsightComponent } from './components/pages/insight/insight.component';
// import { CareerComponent } from './components/pages/career/career.component';
import { WorkComponent } from './components/pages/work/work.component';
import { WorkDetailsComponent } from './components/pages/work-details/work-details.component';


const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'about', component: AboutComponent},
    {path: 'work', component: WorkComponent},
    {path: 'work/:id', component: WorkDetailsComponent},
    // {path: 'careers', component: CareerComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'insights', component: InsightComponent},
    {path: 'service', component: ServiceComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
