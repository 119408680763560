import { Component } from '@angular/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrl: './service.component.scss'
})
export class ServiceComponent {
  services = [
    {
      img: '../../../../assets/img/training.png',
      title: 'Application Development',
      description: 'Our expertise in building containerized applications enables us to create a modern web and mobile experience that is easier to scale, maintain, and update rapidly. By managing application dependencies with a container-based approach, we ensure seamless performance and efficiency.',
    },
    {
      img: '../../../../assets/img/oursource-product-dev.png',
      title: 'Service Monitoring',
      description: 'Our AWS experts ensure continuous monitoring and swift restoration of unplanned interruptions to your AWS cloud services. Using our proven processes and resolution methodologies, we restore your services back to normal efficiently and effectively.',
    },
    {
      img: '../../../../assets/img/roi.png',
      title: 'Infrastructure Optimization',
      description: 'We identify and optimize AWS resources to reduce costs while efficiently handling heavy loads. For instance, we can reduce data transfer costs by identifying and eliminating redundant or unnecessary storage space in your Amazon S3 service.',
    },
    {
      img: '../../../../assets/img/robust-process.png',
      title: 'Cloud Migration',
      description: 'Assess your cloud readiness and enhance IT management with our services, including infrastructure design, ROI assessment, and technical discovery processes. We also develop migration strategies to move workloads seamlessly into public or private clouds without disruption.',
    },
    {
      img: '../../../../assets/img/flexibility.png',
      title: 'DevOps Automation',
      description: 'Analyze key pain points and create a roadmap to automate deployment across your delivery infrastructure. Our AWS-certified experts develop automated scripts for testing, integration, and release cycles, enhancing monitoring visibility and performance measurement.',
    },
    {
      img: '../../../../assets/img/qualified-talent.png',
      title: 'AWS DevOps Talent',
      description: 'DevOps engineers are in high demand, but finding the right talent can be challenging. Our vetted and qualified professionals are ready to join any team immediately and help establish DevOps practices from scratch.',
    }
  ];
}
