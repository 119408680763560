import { Component } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {

  // coreValues = [
  //   {
  //     title: 'Customer-Centric',
  //     description: 'Our top priority is consistently delivering the best outcomes for our clients.'
  //   },
  //   {
  //     title: 'Truthfulness',
  //     description: 'At Alphabyte Insights, truthfulness is in our name. Our clients can rely on us for openness, honesty, and timely feedback. We uphold uncompromising integrity and always act in the best interest of all.'
  //   },
  //   {
  //     title: 'Collaboration',
  //     description: 'We listen to our clients, understand their needs and desires, evaluate available alternatives, and collaborate to find the best solution.'
  //   },
  //   {
  //     title: 'Empowerment',
  //     description: 'Our people are the key to creating outstanding outcomes for our clients. We prioritize them above all else, empowering them with opportunities for education, innovation, and personal growth as they tackle complex problems.'
  //   },
  //   {
  //     title: 'Quality',
  //     description: 'We are committed to delivering reliable products and services. By continuously improving our processes, we ensure that all your needs are consistently met.'
  //   },
  //   {
  //     title: 'Bold',
  //     description: 'Innovation involves breaking new ground, and we embrace risk-taking and learning from mistakes to drive progress. Our bold approach makes us smarter, faster, and better at what we do.'
  //   }
  // ];
  

  // teamMembers = [
  //   {
  //     img: '../../../../assets/img/Bryan+Howlin.jpg',
  //     name: 'Bryan Howlin',
  //     position: 'CHIEF EXECUTIVE OFFICER',
  //     delay: '.2s',
  //     socialLinks: [
  //       { platform: 'facebook', url: '#' },
  //       { platform: 'twitter', url: '#' },
  //       { platform: 'linkedin', url: '#' },
  //       { platform: 'instagram', url: '#' }
  //     ]
  //   },
  //   {
  //     img: '../../../../assets/img/coo.jpeg',
  //     name: 'Vince Verna',
  //     position: 'CHIEF OPERATING OFFICER',
  //     delay: '.4s',
  //     socialLinks: [
  //       { platform: 'facebook', url: '#' },
  //       { platform: 'twitter', url: '#' },
  //       { platform: 'linkedin', url: '#' },
  //       { platform: 'instagram', url: '#' }
  //     ]
  //   },
  //   {
  //     img: '../../../../assets/img/Aaron.jpg',
  //     name: 'Aaron Gabbart',
  //     position: 'BOARD MEMBER',
  //     delay: '.6s',
  //     socialLinks: [
  //       { platform: 'facebook', url: '#' },
  //       { platform: 'twitter', url: '#' },
  //       { platform: 'linkedin', url: '#' },
  //       { platform: 'instagram', url: '#' }
  //     ]
  //   },
  //   {
  //     img: '../../../../assets/img/joe.jpeg',
  //     name: 'Joe Niehaus',
  //     position: 'BOARD MEMBER',
  //     delay: '.8s',
  //     socialLinks: [
  //       { platform: 'facebook', url: '#' },
  //       { platform: 'twitter', url: '#' },
  //       { platform: 'linkedin', url: '#' },
  //       { platform: 'instagram', url: '#' }
  //     ]
  //   }
  // ];

  // clients = [
  //   { imgSrc: '../../../../assets/img/3M.png', delay: '.2s' },
  //   { imgSrc: '../../../../assets/img/bestbuy.png', delay: '.4s' },
  //   { imgSrc: '../../../../assets/img/comcast.png', delay: '.6s' },
  //   { imgSrc: '../../../../assets/img/disney.png', delay: '.8s' },
  //   { imgSrc: '../../../../assets/img/hpe.png', delay: '1s' },
  //   { imgSrc: '../../../../assets/img/nike.png', delay: '1.2s' }
  // ];

  constructor() { }

}
