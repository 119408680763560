<div class="work-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Our Work</h2>
            <p style="font-size: 20px;">We have a proven track record of delivering cutting-edge solutions for our
                clients. We assist them in navigating the challenges associated with new technologies and remain their
                trusted technology partner as they expand their solutions and services.
            </p>
        </div>
    </div>
</div>



<section style="margin: 60px 0 100px;">
    <div class="container" style="width: 75%;">
        <!-- <div *ngFor="let element of worksdetails"> -->
            <img [src]="currentElement.img" alt="" style="width: 1200px; height: 700px;">
            <h3 style="margin-top: 20px;">{{ currentElement.title }}</h3>
            <p>{{ currentElement.description }}</p>
        <!-- </div> -->
    </div>
</section>
